import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'src/app/modals/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogModel } from 'src/app/modals/confirm-dialog/confirm.model';
import { Device } from 'src/app/services/device/device.models';
import { DeviceService } from 'src/app/services/device/device.service';
import { QueryResult } from 'src/app/utils/query-result';

@Component({
  selector: 'app-device-settings',
  templateUrl: './device-settings.component.html',
  styleUrls: ['./device-settings.component.scss']
})
export class DeviceSettingsComponent implements OnInit {

  deviceQueryResult: QueryResult<Device>;
  deviceStatuses: any = {};
  commandOptions = [
    { 'label': '', 'value': '' },
    { 'label': 'Update Config', 'value': 'config' },
    { 'label': 'Update model', 'value': 'object-model' },
    { 'label': 'Update Application', 'value': 'app' },
    { 'label': 'View Logs', 'value': 'logs' }
  ];

  constructor(private deviceService: DeviceService, private snackBar: MatSnackBar,
    public dialog: MatDialog) { }

  ngOnInit(): void {
    this.deviceService.getDevices().subscribe((response) => {
      this.deviceQueryResult = response;
      this.getDeviceStatus();
    })
  }

  getDeviceStatus() {
    let p = []
    this.deviceQueryResult.data.forEach((device) => {
      p.push(this.deviceService.getDeviceStatus(device._id));
    });

    Promise.all(p).then((res) => {
      res.forEach((statusObservable) => {
        statusObservable.subscribe((data: any) => {
          if(data['total']) {
            this.deviceStatuses[data['data'][0]['device_id']] = data['data'][0];
          }
        });
      })
    })
  }

  editDevice(i: number) {
    
  }

  generateConfig(index: number) {
    const message = `Are you sure you want to update the configuration for ${this.deviceQueryResult.data[index].name}?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-modal',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult) {
        this.deviceService.generateConfig(this.deviceQueryResult.data[index]).subscribe(() => {
          this.snackBar.open('Config was successfully generated.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        }, () => {
          this.snackBar.open('Configs could not be generate. Please try again.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        })
      }
    })
  }

  updateObjectModel(index: number) {
    const message = `Are you sure you want to update the object model for ${this.deviceQueryResult.data[index].name}?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-modal',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult) {
        this.deviceService.updateObjectModel(this.deviceQueryResult.data[index]).subscribe(() => {
          this.snackBar.open('Config was successfully generated.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        }, () => {
          this.snackBar.open('Configs could not be generate. Please try again.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        })
      }
    })
  }

  updateApplication(index: number) {
    const message = `Are you sure you want to update the application for ${this.deviceQueryResult.data[index].name}?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-modal',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult) {
        this.deviceService.updateApplication(this.deviceQueryResult.data[index]).subscribe(() => {
          this.snackBar.open('Application was successfully updated.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        }, () => {
          this.snackBar.open('Application could not be updated. Please try again.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        })
      }
    })
  }

  viewLogs(index: number) {
    const message = `Are you sure you want to view logs for ${this.deviceQueryResult.data[index].name}?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-modal',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult) {
        this.deviceService.viewLogs(this.deviceQueryResult.data[index]).subscribe(() => {
          this.snackBar.open('Application was successfully updated.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        }, () => {
          this.snackBar.open('Application could not be updated. Please try again.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        })
      }
    })
  }

  onCommandSelected($event: any, index: number) {
    switch($event) {
      case 'config':
        this.generateConfig(index);
        break;
      case 'app':
        this.updateApplication(index);
        break;
      case 'logs':
        this.viewLogs(index);
        break;
      case 'object-model':
        this.updateObjectModel(index);
        break;
    }
  }

  deleteDevice(index: number) {
    const message = `Are you sure you want to do this?`;
    const dialogData = new ConfirmDialogModel("Confirm Action", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'confirm-modal',
      data: dialogData
    });

    dialogRef.afterClosed().subscribe(dialogResult => {
      if(dialogResult) {
        this.deviceService.deleteDevice(this.deviceQueryResult.data[index]).subscribe(() => {
          this.deviceQueryResult.data.splice(index, 1);
          this.snackBar.open('Device was successfully deleted.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        }, () => {
          this.snackBar.open('Device could not be deleted.', 'dismiss', {
            duration: 5000, horizontalPosition: 'end', verticalPosition: 'bottom',
          });
        });
      }
    });
  }

}
